import "bootstrap/dist/css/bootstrap.css";
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { useUserStore } from './utils/user';
import './global.css'; 
import logger from '@/utils/logger';
import environment from '@/utils/environment';

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);

const reportedErrors = new Set(); // Track reported errors by their message or stack trace

function handleError(error, context = 'Global') {
    const errorKey = error.message || error.stack || JSON.stringify(error); // Unique key for the error
    if (reportedErrors.has(errorKey)) {
        console.log(`Error already reported: ${errorKey}`);
        return; // Skip if this error was already reported
    }

    reportedErrors.add(errorKey); // Mark this error as reported
    console.log('Handling error:', error, context); // Debugging log
    
    const userStore = useUserStore();
    const formData = new FormData();

    formData.append('name', userStore?.name || 'Unknown User');
    formData.append('class', userStore?.class || 'Unknown Class');
    formData.append('reason', 'Application Error');
    formData.append('message', error.message || 'No message available');
    formData.append('logs', logger.getLogs() || 'No logs available');
    formData.append('token', "3ndp324l1q2pld9cod3emhcqru");

    fetch(`${environment.getBaseUrlPHP()}/sendErrorMail.php`, {
        method: 'POST',
        body: formData
    })
    .then(response => response.text())
    .then(result => {
        if (result !== 'success') {
            console.error('Error notification failed to send:', result);
        }
    })
    .catch(err => {
        console.error('Error while attempting to send error notification:', err);
    });

    console.error(`Error in ${context}:`, error);
}

// Capture uncaught errors
window.onerror = (message, source, lineno, colno, error) => {
    console.log('Global error caught:', message); // Add this for debugging
    handleError(error || new Error(message), 'Window');
};

// Capture promise rejections
window.onunhandledrejection = (event) => {
    console.log('Unhandled rejection caught:', event.reason); // Add this for debugging
    handleError(event.reason, 'Promise');
};

app.config.errorHandler = (error, vm, info) => {
    console.log('Vue error handler:', error, info); // Debugging log
    handleError(error, 'Vue Component');
};

app.config.warnHandler = (msg, vm, trace) => {
    console.log('Vue warning handler:', msg, trace); // Debugging log
};

app.mount('#app');

// Initialize login state from sessionStorage on app startup
const userStore = useUserStore();
userStore.initializeAuth();
userStore.name;
