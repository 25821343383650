<template >
    <h1>GS Waggum</h1>
    <div class="d-flex justify-content-center">
        <input class="btn-1" id="error-message-submit-button" type="submit" v-on:click="authenticate" value="LogIn" name="submit-error-message" />
    </div>
</template>

<script>
import logger from '@/utils/logger';
import environment from '@/utils/environment';

export default {
    methods: {
        generateRandomString(length = 16) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
        },

        async authenticate() {
            try {
                const client_id = process.env.VUE_APP_CLIENT_ID;
                const redirect_url = environment.getBaseUrl() + '/callback';
                const auth_url = process.env.VUE_APP_AUTH_URL;
                const state = this.generateRandomString();
                sessionStorage.setItem('state', state);
                const url = `${auth_url}?client_id=${client_id}&redirect_uri=${redirect_url}&response_type=code&scope=openid profile email groups&state=${state}&prompt=login&cache_buster=${new Date().getTime()}`;
                window.location.href = url;

            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source');

    if (sessionStorage.getItem('logoutFlag') === 'true') {
        sessionStorage.removeItem('logoutFlag'); // Clear the flag after checking
        logger.addLog("Skipped auto-login due to recent logout.");
    } else if (source === 'iserv') {
        this.authenticate(); // Automatically authenticate if 'source' is 'Iserv'
    } else {
        logger.addLog("Auto-login skipped as 'source' parameter is not 'Iserv'.");
    }
    }
}
</script>

<style>

</style>