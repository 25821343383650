<template>
    <p style="text-align: center;">Authenticating...</p>
</template>

<script>
import { useUserStore } from '../utils/user';
import logger from '@/utils/logger'; 
import environment from '@/utils/environment'; 

export default {
    name: 'OAuthCallback',
    data: () => ({
        userStore: useUserStore()
    }),
    methods: {
        async exchangeCodeForToken(code) {
            try {
                logger.addLog("Starting token exchange with provided authorization code.");
                const response = await fetch(`${process.env.VUE_APP_HEROKU_URL}${process.env.VUE_APP_TOKEN_URL}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': "Basic " + process.env.VUE_APP_AUTH_CODE,
                    },
                    body: new URLSearchParams({
                        code: code,
                        redirect_uri: environment.getBaseUrl() + '/callback',
                        grant_type: "authorization_code"
                    })
                });
                const data = await response.json();
                
                if (data.access_token) {
                    logger.addLog("Access token retrieved successfully.");
                    if (await this.userStore.login(data.access_token)) {
                        logger.addLog("User login successful, redirecting to profile.");
                        this.$router.push({ name: 'Profile' });
                    } else {
                        logger.addLog("User login failed, redirecting to login.");
                        this.$router.push({ name: 'Login' });
                    }
                } else {
                    logger.addLog("Token retrieval failed, redirecting to login.");
                    this.$router.push({ name: 'Login' });
                }
            } catch (error) {
                logger.addLog(`Error during token exchange: ${error.message}`);
                this.$router.push({ name: 'Login' });
            }
        }
    },

    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const state = urlParams.get('state');
        
        if (state !== sessionStorage.getItem('state')) {
            logger.addLog("State validation failed. Possible CSRF attack detected.");
            this.$router.push({ name: 'Login' });
            return;
        }
        
        const code = urlParams.get('code');
        if (code) {
            logger.addLog("Authorization code found, proceeding with token exchange.");
            this.exchangeCodeForToken(code);
        } else {
            logger.addLog("Authorization code missing, redirecting to login.");
            this.$router.push({ name: 'Login' });
        }
    }
};
</script>
