<template>
<div class="footer justify-content-center d-flex">
    <!-- Render each link dynamically -->
    <router-link
    v-for="(link, index) in footerLinks"
    :key="index"
    class="p-3"
    :to="link.to"
    @click="handleFooterClick(index, link)"
    >
    {{ link.label }}
    </router-link>
</div>
</template>

<script>
import { useUserStore } from '../utils/user';

export default {
data() {
    return {
    footerLinks: [
        { label: 'Impressum', to: '/imprint', original: { label: 'Impressum', to: '/imprint' } },
        { label: 'Kontakt', to: '/contact', original: { label: 'Kontakt', to: '/contact' } },
        { label: 'Fehlermeldung', to: '/error', original: { label: 'Fehlermeldung', to: '/error' } },
        { label: 'FAQ', to: '/faq', original: { label: 'FAQ', to: '/faq' } },
    ],
    };
},
methods: {
    handleFooterClick(index, link) {
    const userStore = useUserStore();

    // Toggle between Login and original link
    if (link.label === 'Login') {
        this.footerLinks.splice(index, 1, link.original); // Switch back to original
    } else if (!userStore.isAuthenticated) {
        this.footerLinks.splice(index, 1, { label: 'Login', to: '/login', original: link }); // Replace with Login
    }
    },
},
};
</script>


<style>

</style>