const environment = {
    getBaseUrl() {
        if (window.location.hostname === 'localhost') {
            return 'http://localhost:8080'; // Local development URL
        }
        return 'https://gswaggum.de'; // Production URL
    },

    getBaseUrlPHP() {
        if (window.location.hostname === 'localhost') {
            return 'http://localhost:8000'; // Local development URL
        }
        return 'https://gswaggum.de'; // Production URL
    }
};

export default environment;
