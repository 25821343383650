<template>
    <h1>Fehlermeldung</h1>

    <!-- Feedback Messages
    <p v-if="successMessage" class="feedback-success">Fehlermeldung wurde erfolgreich gesendet!</p>
    <p v-if="errorMessage" class="feedback-error">Fehler beim Senden der Fehlermeldung. Bitte versuchen Sie es erneut.</p> -->

    <form @submit.prevent="submitAbsence" class="d-flex flex-column gap-3">
        <div class="form">
            <input v-model="reason" type="text" autocomplete="off" name="error-message-regarding" required />
            <label for="error-message-regarding" class="label-name">
                <span class="content-name">Betreff</span>
            </label>
        </div>

        <textarea v-model="message" class="textarea glass" placeholder="Fehlermeldung eingeben..." name="error-message" id="error-message-textarea"></textarea>

        <div class="d-flex justify-content-center">
            <input 
                class="btn-1" 
                id="error-message-submit-button" 
                type="submit" 
                value="Melden" 
                name="submit-error-message"
                :disabled="!isFormValid" 
            />
        </div>
    </form>
</template>


<script>
import { useUserStore } from '@/utils/user';
import logger from '@/utils/logger';
import environment from '@/utils/environment';

export default {
    data() {
        return {
            reason: '',
            message: '',
            successMessage: false,
            errorMessage: false
        };
    },
    computed: {
        isFormValid() {
            return this.reason.trim() !== '' && this.message.trim() !== '';
        }
    },
    methods: {
        async submitAbsence() {
            const userStore = useUserStore();
            const formData = new FormData();
            formData.append('name', userStore.name);
            formData.append('class', userStore.class);
            formData.append('reason', this.reason);
            formData.append('message', this.message);
            formData.append('logs', logger.getLogs());
            formData.append('token', process.env.VUE_APP_SECRET_TOKEN); 

            try {
                const response = await fetch(environment.getBaseUrlPHP() + '/sendErrorMail.php', {
                    method: 'POST',
                    body: formData
                });
                const result = await response.text();
                if (result === 'success') {
                    this.successMessage = true;
                    this.errorMessage = false;
                    alert('Fehlermeldung wurde erfolgreich gesendet!');
                    this.clearForm();
                } else {
                    this.successMessage = false;
                    this.errorMessage = true;
                    alert('Fehler beim Senden der Fehlermeldung. Bitte versuchen Sie es erneut.');
                }
            } catch (error) {
                this.successMessage = false;
                this.errorMessage = true;
                alert('Fehler beim Senden der Fehlermeldung: ' + error);
            }
        },
        clearForm() {
            this.reason = '';
            this.message = '';
        }
    }
}
</script>

<style>

</style>