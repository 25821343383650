<template>
  <div class="pickup">
    <h1>Abholmeldung</h1>
    <form @submit.prevent="sendEmail" class="d-flex flex-column gap-3" v-if="isClassAvailable">
      <div class="form day-form w-50">
        <input v-model="date" type="date" name="absence-day" required/>
        <label for="absence-day-input" class="label-name">
          <span class="content-name lock">Tag</span>
        </label>
      </div>
      <div class="form time-form w-50">
        <input v-model="time" type="time" autocomplete="off" name="absence-time" required/>
        <label for="absence-time" class="label-name">
          <span class="content-name lock">Uhrzeit</span>
        </label>
      </div>
      <div class="form">
        <input v-model="pickupType" type="text" autocomplete="off" name="absence-reason" required/>
        <label for="absence-reason" class="label-name">
          <span class="content-name">Abholart</span>
        </label>
      </div>
      <div class="form">
        <input v-model="destination" type="text" autocomplete="off" name="absence-reason" required/>
        <label for="absence-reason" class="label-name">
          <span class="content-name">Wohin soll das Kind?</span>
        </label>
      </div>
      <div class="d-flex w-100 justify-content-between">
        <p>Ist das Kind in der Ganztagsschule?</p>
        <input v-model="isFullTime" type="checkbox">
      </div>
      <div class="d-flex w-100 justify-content-between">
        <p>Ich habe die <router-link to="privacy-policy">Datenschutzbestimmungen</router-link> gelesen und erkenne diese an.</p>
        <input v-model="agreedToPrivacy" type="checkbox">
      </div>
      <div class="d-flex justify-content-center">
        <input class="btn-1" type="submit" :disabled="!isFormValid" value="Melden" />
      </div>
    </form>

    <p v-else class="feedback-error">Fehler: Es wurde keine Klasse auf IServ zugeteilt. Bitte wenden Sie sich an das Sekretariat.</p>
  </div>
</template>

<script>
import { useUserStore } from '../utils/user';
import logger from '@/utils/logger';
import environment from '@/utils/environment';

export default {
  data() {
    return {
      date: '',
      time: null,
      pickupType: '',
      destination: '',
      isFullTime: false,
      agreedToPrivacy: false,
    };
  },
  computed: {
    isClassAvailable() {
      const userStore = useUserStore();
      const classStatus = userStore.class !== null;
      logger.addLog(`Pickup form: Checked class availability: ${classStatus ? 'Available' : 'Unavailable'}`);
      return classStatus;
    },
    isFormValid() {
      return (
        this.date &&
        this.time &&
        this.pickupType &&
        this.destination &&
        this.agreedToPrivacy
      );
    },
  },
  methods: {
    formatDate(date) {
        if (!date) return '';
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
    },
    isDateValid() {
      if (!this.date) return false;
      const selectedDate = new Date(this.date);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Clear time part for accurate comparison
      return selectedDate >= today;
    },
    sendEmail() {
      if (!this.isDateValid()) {
        alert('Das gewählte Datum darf nicht vor dem heutigen Datum liegen.');
        logger.addLog('Pickup form: Invalid date selected, before today\'s date.');
        return;
      }

      if (!this.agreedToPrivacy) {
        alert('Bitte stimmen Sie den Datenschutzbestimmungen zu.');
        logger.addLog('Pickup form: Privacy agreement not accepted.');
        return;
      }

      logger.addLog('Pickup form: Preparing to send email with form data.');

      const userStore = useUserStore();
      const formData = new FormData();
      formData.append('name', userStore.name);
      formData.append('class', userStore.class);
      formData.append('date', this.formatDate(this.date));
      formData.append('time', this.time);
      formData.append('pickupType', this.pickupType);
      formData.append('destination', this.destination);
      formData.append('isFullTime', this.isFullTime ? 'Ja' : 'Nein');
      formData.append('token', process.env.VUE_APP_SECRET_TOKEN); // Add the secret token

      fetch(environment.getBaseUrlPHP() + '/sendPickupEmail.php', {
        method: 'POST',
        body: formData,
      })
        .then(response => response.text())
        .then(result => {
          if (result === 'success') {
            alert('Email wurde erfolgreich gesendet!');
            logger.addLog('Pickup form: Email sent successfully.');
            this.clearForm();
          } else {
            alert('Fehler beim Senden der Email.');
            logger.addLog('Pickup form: Email sending failed with response: ' + result);
          }
        })
        .catch(error => {
          alert('Fehler beim Senden der Email: ' + error);
          logger.addLog('Pickup form: Error during email sending - ' + error.message);
        });
    },
    clearForm() {
      this.date = '';
      this.time = null;
      this.pickupType = '';
      this.destination = '';
      this.isFullTime = false;
      this.agreedToPrivacy = false;
      logger.addLog('Pickup form: Form fields cleared after submission.');
    }
  }
};
</script>
